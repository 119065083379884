import request from '@/utils/request.js';
const BASEURL = process.env.VUE_APP_BASE_URL;

export function JUDGES(params) {
  return request({
    url: BASEURL + '/Home/Web/judges',
    method: 'POST',
    params,
  });
}
export function JUDGES_DETAIL(params) {
  return request({
    url: BASEURL + '/Home/Web/judges_detail',
    method: 'GET',
    params,
  });
}
export function GET_NEWS(params) {
  return request({
    url: BASEURL + '/Home/Web/news',
    method: 'POST',
    params,
  });
}
export function NEWS_DETAIL(params) {
  return request({
    url: BASEURL + '/Home/Web/news_detail',
    method: 'GET',
    params,
  });
}
export function GET_SPEAKERS(params) {
  return request({
    url: BASEURL + '/Home/Web/speakers',
    method: 'POST',
    params,
  });
}

export function SPEAKERS_DETAIL(params) {
  return request({
    url: BASEURL + '/Home/Web/speakers_detail',
    method: 'GET',
    params,
  });
}
export function AMBASSADOR(params) {
  return request({
    url: BASEURL + '/home/web/ambassador',
    method: 'POST',
    params,
  });
}
export function CHAPTER(params) {
  return request({
    url: BASEURL + '/home/web/chapter',
    method: 'POST',
    params,
  });
}
export function JOIN_US(params) {
  return request({
    url: BASEURL + '/home/web/join_us',
    method: 'POST',
    params,
  });
}
