<template>
  <div class="main">
    <div class="container">
      <div class="news_header">
        <div class="title">{{ detail.news_title }}</div>
        <div class="time">{{ detail.pub_date }}</div>
      </div>
      <div class="news_detail">
        <!-- <template v-if="detail.img.length > 0">
          <img
            v-for="(item, index) in detail.img"
            :key="index"
            :src="$OSSURL +  item "
          />
        </template> -->
        <div class="text" v-html="detail.news_content"></div>
      </div>
      <div class="backBtn" @click="pageBack"></div>
    </div>
  </div>
</template>

<script>
import { NEWS_DETAIL } from '@/api/index.js';

export default {
  name: 'Index',
  components: {},
  data() {
    return {
      id: this.$route.query.id,
      detail: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    pageBack() {
      this.$router.go(-1);
    },
    getDetail() {
      NEWS_DETAIL({ id: this.id }).then(res => {
        this.detail = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
</style>
